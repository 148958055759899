import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import translate from "../utils/translation"
import styles from "./about.module.scss"


class AboutTemplate extends React.Component {

  render() {
    const post = this.props.data.markdownRemark
    const videoRoot = this.props.data.site.siteMetadata.videoRoot
    const imageRoot = this.props.data.site.siteMetadata.imageRoot
    const lang = post.fields.slug.split('/')[1]
    const { altPost, parent } = this.props.pageContext
    const siteTitle = translate('site.name', lang)
    const headerSubTitle = parent && parent.frontmatter.title ? parent.frontmatter.title : translate('site.description', lang)
    const background = post.frontmatter.background ? `url(${imageRoot}/${post.frontmatter.background})` : `none`
    const hasBackGround = post.frontmatter.background ? true : false
    const images = hasBackGround ? [post.frontmatter.background] : []

    return (
      <Layout location={this.props.location} title={siteTitle} subTitle={headerSubTitle} slug={post.fields.slug} isReverse={true} world={hasBackGround} hasLanguage={!!altPost}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          lang={lang}
          images={images}
          slug={post.fields.slug}
        />
        <article className="main-content">
          <div className={`sub-content overview about ${hasBackGround ? 'has-background' : ''}`}
            style={{
              backgroundImage: background,
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
            }}
          >
            <div>
              <h1>{post.frontmatter.title}</h1>
              <hr></hr>
              <h2>{altPost ? altPost.frontmatter.title : ''}</h2>
            </div>
            {
              parent && parent.frontmatter.title && (
                <div className="footer">
                  <Link rel="back"
                    to={parent.fields.slug}
                    style={{
                      boxShadow: `none`,
                      textDecoration: `none`,
                      color: `inherit`,
                    }}>
                    <button>Back</button>
                  </Link>
                </div>
              )
            }
          </div>
          <div className="sub-content detail">
            {
              post.frontmatter.videos && (
                <div className={styles.container}>
                  {
                    post.frontmatter.videos &&
                    post.frontmatter.videos.map(
                      (v, i) => {
                        return <div key={i} className={styles.video}>
                          <h1>{post.frontmatter.videoTitles[i]}</h1>
                          <h2>{post.frontmatter.videoDescriptions[i]}</h2>
                          <video controls controlsList="nodownload"
                            poster={`${videoRoot}/${v.substring(0, v.length - 4)}.jpg`}>
                            <source src={`${videoRoot}/${v}`} type="video/mp4"></source>
                          </video>
                          <p className={styles.caption}>{post.frontmatter.videoCaptions[i]}</p>
                        </div>
                      }
                    )
                  }
                </div>
              )
            }
            <div dangerouslySetInnerHTML={{ __html: post.html }} className={`inner ${post.frontmatter.videos ? 'hasvideo' : ''}`}></div>
          </div>
        </article>
      </Layout>
    )
  }
}

export default AboutTemplate

export const pageQuery = graphql`
  query AboutBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        videoRoot
        imageRoot
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        videos
        videoTitles
        videoDescriptions
        videoCaptions
        background
      }
    }
  }
`
